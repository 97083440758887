<template>
  <li @click="closeProvidersModal">
    <nuxt-link :to="localizePath(`/providers/${props.provider.identity}`)" class="provider">
      <atomic-image class="provider__logo" :src="logoSrc" defaultImage="/img/provider-empty-placeholder.svg" />
    </nuxt-link>
  </li>
</template>

<script setup lang="ts">
  import type { IGameProvider } from '~/types';

  const props = defineProps<{
    provider: IGameProvider;
  }>();

  const { localizePath, getProvidersLogoSrc } = useProjectMethods();

  const logoSrc = getProvidersLogoSrc(props.provider);

  const { closeModal } = useModalStore();

  const closeProvidersModal = async (): Promise<void> => {
    await closeModal('providers');
  };
</script>

<style src="~/assets/styles/components/modal/providers-list-item.scss" scoped></style>
