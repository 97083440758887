<template>
  <vue-final-modal :focusTrap="false" class="modal-providers" :class="{ 'keyboard-open': isKeyboardOpen }">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'title') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('providers')" />
      </div>
    </div>

    <div class="vfm__body">
      <form-input-search
        v-model:value="searchValue"
        :placeholder="getContent(props.currentLocaleData, props.defaultLocaleData, 'searchPlaceholder')"
        is-providers
        autoFocus
        @input="onSearch"
      />

      <div class="modal-providers__content">
        <div v-if="isShowEmpty && searchValue" class="modal-providers__not-found">
          <div class="modal-providers__not-found-title">
            <span>"{{ searchValue }}"</span>

            {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'empty.title') }}
          </div>

          <div class="modal-providers__not-found-subtitle">
            {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'empty.description') }}
          </div>
        </div>
        <template v-if="!searchValue || isShowEmpty">
          <h3 class="modal-providers__subtitle">
            {{ firstSubtitle }}
          </h3>

          <providers-modal-list :providers="providersOfTheMonth" />

          <h4 class="modal-providers__subtitle">
            {{ secondSubtitle }}
          </h4>

          <providers-modal-list :providers="frequentlySearchedProviders" />
        </template>
        <div v-else-if="searchValue && !isShowEmpty" class="modal-providers__search-result">
          <providers-modal-list :providers="providersList" />
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import debounce from 'lodash/debounce';
  import type { CIModalsContent, IGameProvider, IProvidersRequest } from '~/types';

  const props = defineProps<{
    currentLocaleData: Maybe<CIModalsContent['providers']>;
    defaultLocaleData: Maybe<CIModalsContent['providers']>;
  }>();

  const { closeModal } = useModalStore();
  const { gameProviders } = useGamesStore();
  const { getContent } = useProjectMethods();

  const searchValue = ref<string>('');
  const searchProviders = ref<IGameProvider[]>([]);
  const isShowEmpty = ref<boolean>(false);

  const providersList = computed(() => {
    const providers = (!searchValue.value ? gameProviders : searchProviders.value) || [];
    return providers.filter(provider => !!provider.gameEnabledCount);
  });

  const providersOfTheMonth = computed(() => {
    return providersList.value.slice(0, 8);
  });

  const frequentlySearchedProviders = computed(() => {
    return providersList.value.slice(8);
  });

  const firstSubtitle = computed(() => {
    return getContent(props.currentLocaleData, props.defaultLocaleData, 'firstSubtitle');
  });
  const secondSubtitle = computed(() => {
    return getContent(props.currentLocaleData, props.defaultLocaleData, 'secondSubtitle');
  });

  const { alertsData, defaultLocaleAlertsData } = useGlobalStore();

  const requestParams = reactive<IProvidersRequest>({
    name: undefined,
    sortBy: undefined,
    sortOrder: undefined,
  });

  const { showAlert } = useLayoutStore();

  const { getGameProviders } = useCoreGamesApi();

  const getProviders = async (): Promise<void> => {
    try {
      const responseProviders = await getGameProviders(requestParams);
      searchProviders.value = responseProviders || [];
      isShowEmpty.value = !responseProviders.length;
    } catch {
      showAlert(alertsData?.global?.somethingWrong || defaultLocaleAlertsData?.global?.somethingWrong);
    }
  };

  const onSearch = debounce(
    async (searchValue: string | undefined): Promise<void> => {
      requestParams.name = searchValue?.length && searchValue?.length >= 1 ? searchValue : undefined;
      await getProviders();
    },
    500,
    { leading: false }
  );

  const isKeyboardOpen = ref(false);
  const initialHeight = window.visualViewport?.height;

  const handleResize = () => {
    const currentHeight = window.visualViewport?.height;
    if (currentHeight && initialHeight) {
      isKeyboardOpen.value = currentHeight < initialHeight;
    }
  };

  onMounted(() => {
    window.visualViewport?.addEventListener('resize', handleResize);
  });

  onBeforeUnmount(() => {
    window.visualViewport?.removeEventListener('resize', handleResize);
  });
</script>

<style src="~/assets/styles/components/modal/providers.scss" lang="scss" />
