<template>
  <ul class="providers-list">
    <providers-modal-list-item v-for="provider in props.providers" :key="provider.id" :provider="provider" />
  </ul>
</template>

<script setup lang="ts">
  import type { IGameProvider } from '~/types';

  const props = defineProps<{
    providers: IGameProvider[];
  }>();
</script>

<style src="~/assets/styles/components/modal/providers-list.scss" lang="scss" scoped></style>
